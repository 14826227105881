















import Vue from "vue";
import Component from "vue-class-component";
import { Auth } from "@/store/modules";

@Component
export default class Home extends Vue {
  timeout: any = null;
  
  get redirectUrl() {
    return this.$route.query.redirectUrl as string || '/';
  }

  redirect() {
    clearTimeout(this.timeout);
    this.$router.push(this.redirectUrl as string);
  }

  mounted() {
    Auth.refreshAccountData();
    this.timeout = setTimeout(() => {
      this.$router.push(this.redirectUrl as string);
    }, 6000);
  }
}
